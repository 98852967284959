<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
    :title="t(item.title)"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
      :data-route="item.route"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { onMounted, onBeforeUnmount } from '@vue/composition-api'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    const handleKeydown = event => {
      if (props.item.hotkey) {
        const keyCombination = props.item.hotkey.toLowerCase()
        const pressedCombination = [
          event.ctrlKey ? 'ctrl' : '',
          event.shiftKey ? 'shift' : '',
          event.altKey ? 'alt' : '',
          event.key.toLowerCase(),
        ].filter(Boolean).join('+')

        if (pressedCombination === keyCombination) {
          // Perform the action for this menu item
          event.preventDefault()
          updateIsActive() // Optional: mark the item as active
          event.preventDefault()

          // Emulate a click
          const linkElement = document.querySelector(`[data-route="${props.item.route}"]`)
          if (linkElement) {
            linkElement.click()
          } else {
            console.warn('Link element not found for route:', props.item.route)
          }
        }
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', handleKeydown)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', handleKeydown)
    })

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
  data() {
    return {
      cbs_class: {
        active: false,
        disabled: false,
      },
    }
  },

}
</script>
